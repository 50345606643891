import React from 'react';
import * as styles from './CustomCheackBox.module.scss';

const CustomCheackBox = ({
  name,
  checked,
  handleInputChange,
  isGlobalVotePage,
  id,
}: {
  name: string;
  checked?: boolean;
  handleInputChange: (e: any, id: any) => void;
  isGlobalVotePage?: boolean;
  id: string | undefined;
}) => {
  return (
    <div>
      <input
        type='checkbox'
        className={!isGlobalVotePage ? 'custom-checkbox' :  'custom-checkboxGlobal'}
        id={id}
        name={name}
        value={name}
        checked={checked}
        onChange={(e) => handleInputChange(e, id)}
      />
      <label htmlFor={id}>{name}</label>
    </div>
  );
};

export default CustomCheackBox;
