import React, { useEffect, useState } from "react";

import Layout from "../component/Layout";
import { Helmet } from "react-helmet-async";
import SeasonTop from "../component/SeasonTop";

import CheckboxBlockGlobalVote from "../component/CheckboxBlockGlobalVote/CheckboxBlockGlobalVote";
import useResults from "../hooks/use-results";
import InformModal from "../component/InformModal";
import BattleBlockGlobalVotes from "../component/BattleBlockGlobalVotes";
import * as styles from "./../component/CheckboxBlockGlobalVote/CheckboxBlockGlobalVote.module.scss";
import { AuthContext } from "../context/auth/authContext";
import { navigate } from "gatsby";
import useProfile from "../hooks/use-profile";
import NoNFT from "../component/NFTList/NoNFT";
import HeaderPage from "../component/Profile/HeaderPage";
import Spinner from "../component/Spinner";

const SeasonPolls = () => {
  const { getGlobalPolls, globalPolls } = useResults();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    getGlobalPolls();
  }, []);

  const { isAuthentificated } = React.useContext(AuthContext);
  const { isSeasonTokenExists, isLoading } = useProfile();

  const [modalTitle, setmodalTitle] = useState("");
  const [modalText, setmodalText] = useState("");

  // useEffect(() => {
  //   if (!isAuthentificated) {
  //     navigate("/");
  //   }
  // }, []);

  const toggleModal = (isVisible: boolean) => {
    setIsVisible(!isVisible);
  };

  const successVoted = () => {
    setmodalTitle("Thank you for voting!");
    // setmodalText(
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"
    // );
    setIsVisible(true);
  };

  return (
    <>
      <Helmet>
        <title> Season Polls - Reds Club</title>
        <meta property='og:title' content='Season Polls   - Reds Club' />
        <meta name='description' content='Season Polls  ' />
        <meta property='og:description' content='Season Polls ' />
      </Helmet>
      <Layout>
        <main className={styles.container}>
          <section className={!isSeasonTokenExists ? styles.myNFT : ""}>
            {isSeasonTokenExists ? (
              <>
                {/* <SeasonTop /> */}
                <section className='globalPollSection'>
                  {globalPolls.map((poll) => (
                    <div className='globalPollItem' key={poll.id}>
                      <BattleBlockGlobalVotes title={poll.title} description={poll.description} />
                      <CheckboxBlockGlobalVote globalPoll={poll} successVoted={successVoted} />
                    </div>
                  ))}
                </section>
              </>
            ) : (
              <div>
                {isLoading && <Spinner />}
                <HeaderPage title='Reds Club NFT Awards' />
                {!isLoading && (
                  <NoNFT
                    title={
                      "Here you can find season polls when you get membership NFT and start voting with it"
                    }
                  />
                )}
              </div>
            )}

            {isVisible && (
              <InformModal
                toggleModal={() => toggleModal(isVisible)}
                title={modalTitle}
                contentText={modalText}
              />
            )}
          </section>
        </main>
      </Layout>
    </>
  );
};

export default SeasonPolls;
