// extracted by mini-css-extract-plugin
export var bodyFooter = "CheckboxBlockGlobalVote-module--bodyFooter--4-QmY";
export var chackBoxItem = "CheckboxBlockGlobalVote-module--chackBoxItem--e87RI";
export var checkboxBlock = "CheckboxBlockGlobalVote-module--checkboxBlock--+kdf0";
export var container = "CheckboxBlockGlobalVote-module--container--+THpx";
export var containerCheckBox = "CheckboxBlockGlobalVote-module--containerCheckBox--qyuAO";
export var content = "CheckboxBlockGlobalVote-module--content--fv-5H";
export var empty = "CheckboxBlockGlobalVote-module--empty--Xpa3T";
export var myNFT = "CheckboxBlockGlobalVote-module--myNFT--8aLCW";
export var rightBlock = "CheckboxBlockGlobalVote-module--rightBlock--f1sLs";
export var row = "CheckboxBlockGlobalVote-module--row--jGVL0";
export var submit = "CheckboxBlockGlobalVote-module--submit--h8VSn";
export var title = "CheckboxBlockGlobalVote-module--title--e14US";