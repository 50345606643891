// extracted by mini-css-extract-plugin
export var buttonAdditional = "InformModal-module--buttonAdditional--6oEGe";
export var closeButton = "InformModal-module--closeButton--yOVR1";
export var container = "InformModal-module--container--axmkm";
export var content = "InformModal-module--content--rSCN7";
export var description = "InformModal-module--description--hHbVi";
export var header = "InformModal-module--header--nuTRP";
export var image = "InformModal-module--image--B86Rw";
export var lightbox = "InformModal-module--lightbox--J-Kux";
export var modalCard = "InformModal-module--modalCard--0FMrj";
export var modalHitbox = "InformModal-module--modalHitbox--UeGbd";
export var provideName = "InformModal-module--provideName--uJptu";
export var providerContainer = "InformModal-module--providerContainer--hZrbL";
export var providerDescription = "InformModal-module--providerDescription--tJ8Oy";
export var providerIcon = "InformModal-module--providerIcon--wC2Rv";
export var providerWrapper = "InformModal-module--providerWrapper--g6jXI";
export var title = "InformModal-module--title--nlMtA";