import React from "react";
import * as styles from "./InformModal.module.scss";
import close from "../../images/liverpul/close.svg";
import { Link } from "gatsby";
import Button from "../common/Button";

const InformModal = ({
  toggleModal,
  title,
  contentText,
}: {
  toggleModal: () => void;
  title: string;
  contentText: string;
}) => {
  return (
    <section onClick={toggleModal} className={styles.lightbox}>
      <div className={styles.container}>
        <div className={styles.modalHitbox}></div>
        <div className={styles.modalCard}>
          <div className={styles.closeButton} onClick={toggleModal}>
            <img src={close} alt='close' />
          </div>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{contentText}</div>
              <Link to='/' className={` ${styles.buttonAdditional}`}>
                <Button>main page</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InformModal;
