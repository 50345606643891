import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import { resultAPI } from "../../api/api";
import { MatchContext } from "../../context/match/matchContext";
import { IGlobalPoll } from "../../context/result/resultReducer";
import Button from "../common/Button";
import Spinner from "../Spinner";
import * as styles from "./CheckboxBlockGlobalVote.module.scss";
import CustomCheackBox from "./CustomCheackBox";

const CheckboxBlock = ({ globalPoll, successVoted }: { globalPoll: IGlobalPoll, successVoted: () => void }) => {
  const players = globalPoll.options;
  const pollId = globalPoll.id;

  const playersWithChecked = players?.map((item) => {
    return { ...item, checked: false };
  });

  const [state, setState] = useState(playersWithChecked);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (playersWithChecked) {
      setState(playersWithChecked);
    }
  }, [players]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { name, value, checked } = e.target;
    const newState = state.map((item) => {
      if (item._id === id) {
        item.checked = checked;
      } else {
        item.checked = false;
      }

      return item;
    });

    setState(newState);
  };

  const handlerSubmit = async () => {
    const data = state
      .filter((item) => item.checked === true)
      .map((item) => item.footballer);

    if (!data.length) {
      toast.error("choose some field");
      return;
    }

    const dataForm: any = {
      footballer: data[0],
    };
    if (pollId) {
      try {
        setLoading(true);
        const responce = await resultAPI.setGlobalPoll(pollId, dataForm);
        toast.info("Successfully");
        successVoted()
      } catch (error) {
        console.log("error", error);
        if (axios.isAxiosError(error)) {
          const err = error as AxiosError;
          // do something
          // or just re-throw the error
          // toast.error(error)
          toast.error(err.response?.data.message);
        }
      } finally {
        setLoading(false);
        resetForm();
      }
    }
  };

  const resetForm = () => {
    const newState = state.map((item) => {
      return { ...item, checked: false };
    });
    setState(newState);
  };

  return (
    <>
      <div className={styles.checkboxBlock}>
        <div className={styles.containerCheckBox}>
          <div className={styles.row}>
            {state &&
              state.map((item, i) => {
                return (
                  <div className={styles.chackBoxItem} key={i}>
                    <CustomCheackBox
                      handleInputChange={handleInputChange}
                      id={item._id}
                      isGlobalVotePage={true}
                      checked={item.checked}
                      name={item.footballer}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        {loading && <Spinner />}
      </div>
      <div className={styles.bodyFooter}>
        <div className={styles.empty}> </div>
        <div className={styles.submit}>
          <Button isWhiteBackGround={true} onClick={handlerSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default CheckboxBlock;
